import { FC, useMemo } from 'react';
import FileService from '../../../../services/FileService';
import { FileInfo } from '../../../shared/file-upload/FileInfo';
import ExternalLinkIcon from '../../../shared/icon/ExternalLink';
import ActionPreview from '../../ActionPreview';
import ActionBaseProps from '../ActionBaseProps';
import { useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import { file } from 'jszip';
import { DocumentResponse } from '../../../../models/Document';
import { FormType } from '../../../../models/FormTypes';
import StringUtils from '../../../../utils/StringUtils';

const MultiFileUploadPreview: FC<ActionBaseProps<FileInfo[]>> = (props) => {
  const { response, data } = props;
  const { question, previewQuestion, previewDescription } = data;

  const openFile = (file: FileInfo) => {
    FileService.getFile(file.id).then((fileBlob) => {
      const url = URL.createObjectURL(fileBlob);

      const link = document.createElement('a');
      link.target = '_blank';
      link.href = url;
      link.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    });
  };

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);
  const { clientForm } = useFormRendererInfo();

  const answer = useMemo(() => {
    if (!response) {
      return '-';
    }

    const getTitle = (file: FileInfo) => {
      if (clientForm && clientForm.type === FormType.Document && clientForm.attachments) {
        const fileIndex = clientForm.attachments.findIndex((x) => x.fileId === file.id);
        if (fileIndex !== undefined) {
          const documentResponse = clientForm as DocumentResponse;
          return `${StringUtils.makePrefixWithNumber(
            documentResponse.prefix,
            documentResponse.documentNumber,
            documentResponse.templateModuleTranslations,
          )}-${StringUtils.addLeadingZeros(fileIndex + 1)}-${file.name}`;
        }
      }
      return file.name;
    };

    return response.map((file, i) => (
      <span key={i} className="cursor-pointer" onClick={() => openFile(file)}>
        <span className="underline">{getTitle(file)}</span> <ExternalLinkIcon className="h-6 w-6" />
      </span>
    ));
  }, [clientForm, response]);

  return <ActionPreview data-cy="multi-file-preview" question={title} description={previewDescription} answer={answer} />;
};

export default MultiFileUploadPreview;
